.group-horizontal > label > input[type='radio']:checked + mark:hover {
  background-color: #4596e6;
}

.selection-table.entry > article > div > .group-horizontal > label {
  flex-grow: 1;
  min-width: 0;
}

.selection-table.entry > article > div > .group-horizontal > label > input[type='radio'] + mark {
  min-height: 34px;
}

.group-horizontal {
  display: flex;
}

.group-horizontal-hidden {
  height: 40px;
}

.group-horizontal > label {
  border-radius: 0;
  border-width: 1px 0;
  min-width: 150px;
}

.group-horizontal > label {
  min-width: 172px;
}

.group-horizontal > label + label {
  margin-left: 0;
}

.group-horizontal > label > input[type='radio'] + mark{
  align-items: center;
  background: none transparent;
  background-clip: border-box;
  background-color: #fff;
  border: 0 none;
  border: 0 solid rgba(153,153,153,.3);
  border-radius: 0;
  border-width: 1px 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  line-height: 1;
  margin: 0;
  min-height: 40px;
  padding: 12px 0;
  width: 100%;
}

.group-horizontal > label:hover > input[type='radio'] + mark {
  background-color: #c1d1eb;
}

.group-horizontal > label > input[type='radio']:checked + mark {
  background-color: #3f71bd;
  border-color: #3f71bd;
  color: #fff;
  font-weight: 900;
}

.group-horizontal > label:first-child > input[type='radio'] + mark {
  border-left-width: 1px;
  border-radius: 4px 0 0 4px;
}

.group-horizontal > label:last-child > input[type='radio'] + mark {
  border-right-width: 1px;
  border-radius: 0 4px 4px 0;
}

.box-applicant-search > section > .group-horizontal {
  margin-left: 32px;
}

.box-applicant-search > section > .group-horizontal {
  margin-left: 32px;
}

.group-horizontal-mark {
  position:relative;
  top:-20px;
}
